import Vue from "vue";
import VueRouter from "vue-router";
//import HomeView from "../views/HomeView.vue";
//import Home2View from "../views/Home2View.vue";
//import FormView from "../views/FormView.vue";
import ContactView from "../views/ContactView.vue";
import MentionsView from "../views/MentionsView.vue";
import DoneView from "../views/DoneView.vue";
import WaitView from "../views/WaitView.vue";
import FinishView from "../views/FinishView.vue";
import PochoirView from "../views/PochoirView.vue";
import ErrorView from "../views/404View.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "404",
    component: ErrorView,
  },
  {
    path: "/",
    name: "home",
    component: FinishView,
  },
  {
    path: "/home",
    name: "home2",
    component: FinishView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/pochoir",
    name: "pochoir",
    component: PochoirView,
  },
  {
    path: "/form",
    name: "form",
    component: FinishView,
  },
  {
    path: "/done",
    name: "done",
    component: DoneView,
  },
  {
    path: "/wait",
    name: "wait",
    component: WaitView,
  },
  {
    path: "/finish",
    name: "finish",
    component: FinishView,
  },
  {
    path: "/mentions",
    name: "mentions",
    component: MentionsView,
  },
  {
    path: "/status/:_id/:_secu",
    name: "Status",
    component: () => import("../views/StatusView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
